export class FieldUpdateDb {

  callback = () => {return {}}

  options = {
    type: "",
    dbname: "",
  }

  constructor(type, dbname) {
    this.options = {
      type,
      dbname
    }
  }


  // submit(oid, field_value, old_value, new_value) {
  //   if (!this.options.oid) {
  //     console.error("oid是空的")
  //     return
  //   }
  //
  //   let url = this.options.type === 'prj' ? '/prj/update/onefield' : '/pro/update/onefield'
  //
  //   return fetch('post', url, {
  //     field_id: this.options.dbname,
  //     field_name: this.options.field_name,
  //     field_value,
  //     oid: this.options.oid,
  //     prjoid: this.options.prjoid,
  //     old_value,
  //     new_value
  //   }).then((res) => {
  //     if (res.status === 0) {
  //       message.success('修改成功！')
  //       return Promise.resolve()
  //     } else {
  //       message.error(res.message)
  //     }
  //   })
  // }
}

