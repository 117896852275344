import { render, staticRenderFns } from "./projectsetOperation.vue?vue&type=template&id=48d4acf6&scoped=true&"
import script from "./projectsetOperation.vue?vue&type=script&lang=js&"
export * from "./projectsetOperation.vue?vue&type=script&lang=js&"
import style0 from "./projectsetOperation.vue?vue&type=style&index=0&id=48d4acf6&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d4acf6",
  null
  
)

export default component.exports