<template>
    <div class="app-main-content">
        <div class="content">
            <div class="title">
                项目操作
            </div>
            <div class="line"></div>
            <div class="operation_cont">
                <div class="opera_title" style="font-weight: 600;">
                    基本信息
                </div>
                <div class="row">
                    <div class="row_title">
                        项目名称
                    </div>
                    <div class="row_cont">
                      <a-input v-model="project_name"  style="width:560px;margin-right: 8px;" />
                      <a-button @click="updatePrjName" type="primary" v-if="project_name !== detail.project_name">
                          更新
                      </a-button>
                    </div>
                </div>
                <div class="row">
                  <div class="row_title">
                    创建者
                  </div>
                  <div class="row_cont">
                    <img :src="detail.creator_pic" style="margin-right: 5px;" alt="" class="pic">
                    {{ detail.creator_title ? detail.creator_name + '-' + detail.creator_title : detail.creator_name}}
                  </div>
                </div>
                <div class="row">
                    <div class="row_title">
                        创建时间
                    </div>
                    <div class="row_cont">
                      {{ detail.create_time }}
                    </div>
                </div>
                <div class="row">
                    <div class="row_title">
                        是否是模板
                    </div>
                    <div class="row_cont">
                      <a-radio-group v-model="detail.is_template" @change="onChange">
                        <a-radio value="1">
                          是
                        </a-radio>
                        <a-radio value="0">
                          否
                        </a-radio>
                      </a-radio-group>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="operation_cont">
                <div class="opera_title">
                    复制项目
                </div>
                <p>复制当前项目的项目组件、工作项类型、权限配置以及项目配置，可自定义需要复制的项目数据。</p>
                <a-button @click="copyProject">复制项目</a-button>
            </div>
            <template v-if="$hasP('P_DEL')">
              <div class="line"></div>
              <div class="operation_cont">
                <div class="opera_title">
                  删除项目
                </div>
                <p>删除该项目所有的资源，将无法恢复。</p>
                <a-button type="danger" ghost @click="deleteProject">
                  删除
                </a-button>
              </div>
            </template>
            <template v-if="$hasP('P_LOCK')">
              <div class="line"></div>
              <div class="operation_cont">
                <div class="opera_title">
                  归档项目
                </div>
                <p>归档后，只有项目管理员可以对项目内容进行更改，其余成员可以查看项目但无法执行其他操作；取消后，成员的操作权限将还原到正常状态。</p>
                <a-button type="danger" ghost @click="toggleStatus">
                  {{ detail.business_status !== '1000' ? '归档' : '取消归档' }}
                </a-button>
              </div>
            </template>
        </div>

    </div>
</template>

<script>
import {fetch} from "@/utils/request.js";
import {FieldUpdateDb} from "@/utils/FieldUpdate.js";

export default {
  data() {
    return {
      detail: {},
      project_name: "",
      dbs: {
        prjCore: new FieldUpdateDb("prj", "prj_core")
      }
    }
  },
  async created() {
    this.getDetail()
  },
  computed: {
    hasSettingP() {
      let useroid = JSON.parse(localStorage.getItem('company_info')).account_oid
      return this.info.admins?.find(item => item.oid === useroid) || this.info.creator_oid === useroid
    }
  },
  methods: {
    toggleStatus() {
      let oldValue = {
        '0': '未开始',
        '100': '进行中',
        '500': '已完成',
        '1000': '已归档'
      }[this.detail.business_status]

      let to = "1000"
      let newValue = '已归档'

      if (this.detail.business_status === "1000") {
        to = "100"
        newValue = '进行中'
      }
      this.$updateOnefield("prj", "prj_core", "business_status", to, this.detail.oid, this.$route.query.prj_oid, oldValue, newValue).then(() => {
        this.getDetail()
      })
    },
    deleteProject() {
      this.$confirm({
        title: '确定要删除项目吗?',
        onOk: () => {
          return fetch("post", "/prj/delete", {oid: this.detail.oid}).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功")
              this.$router.push("/project/list")
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      });
    },
    copyProject() {
      this.$confirm({
        title: '确定要复制项目吗?',
        onOk: () => {
          return fetch("post", "/prj/create", {copy_project_oid: this.detail.oid}).then(res => {
            if (res.status === 0) {
              this.$message.success("复制成功")
              this.$router.push({
                path: "/project/overview",
                query: {
                  prj_oid: res.message
                }
              })
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      });
    },
    updatePrjName() {
      this.$updateOnefield("prj", "prj_core", "project_name", this.project_name, this.detail.oid, this.$route.query.prj_oid, this.detail.project_name, this.project_name)
        .then(() => {
          this.getDetail()
        })
    },
    getDetail() {
      fetch("post", "/prj/info", {
        oid: this.$route.query.prj_oid
      }).then(res => {
        if (res.status === 0) {
          this.detail = res.data
          this.project_name = res.data.project_name
        }
      })
    },
    onChange(e) {
      console.log(e.target.value);
      let newValue = e.target.value
      let oldValue = newValue === "1" ? "0" : "1"
      this.$updateOnefield("prj", "prj_core", "is_template", newValue, this.detail.oid, this.$route.query.prj_oid, oldValue, newValue).then(() => {
        this.getDetail()
        // if(newValue === '1') {
        //   this.project_name = `【模版】${this.project_name}`
        //   this.updatePrjName()
        // } else {
        //   let index = this.project_name.indexOf('【模版】')
        //   this.project_name = index !== -1 ? this.project_name.substring(index + 4, this.project_name.length) : this.project_name
        //   this.updatePrjName()
        // }
      })
    }
  }
}
</script>
<style scoped lang="less">
.app-main-content{
    padding: 0 20px 15px 20px;
}
.content {
    background-color: #fff;
    padding: 0 42px 0 21px;
    line-height: 1;
    box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
    .line {
        background-color: #EDEDED;
        width: 100%;
        height: 2px;
        margin-bottom: 25px;
    }
    .pic{
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
        color: #3B3B3B;
        line-height: 58px;
    }

    .operation_cont {
        padding-bottom: 24px;

        .opera_title {

            font-size: 16px;
            color: #3B3B3B;


        }
        p{
            margin: 12px 0 22px 0;
        }
        .row {
            display: flex;
            align-items: center;
            margin-top: 22px;
            font-size: 16px;

            .row_title {
                width: 112px;
                color: #3B3B3B;
            }

            .row_cont {
                display: flex;
                justify-items: center;

                color: #4D4D4D;
            }
        }
    }

}
</style>
